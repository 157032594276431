<script>
export default {
  name: 'EmployeeCard',
  props: {
    employee: {
      type: [Array, Object]
    }
  },
  
  data () {
    return {
    }
  },
  
  methods: {
  }
}
</script>

<template>
    <div class="employee-card">
      <div class="employee-card__img">
        <img :src="`/employees/${employee.id}.jpg`">
      </div>
  
      <div class="employee-card__title">{{employee.first_name}} {{employee.second_name}}</div>
  
      <div class="employee-card__deratment">{{employee.department}}</div>
      <div class="employee-card__text">{{employee.position}}</div>
      
      <div class="employee-card__contact">
        <a :href="'tel:' + employee.phone_number">
          {{employee.phone_number}}
        </a>
      </div>
      
      <div class="employee-card__contact">
<!--        <v-icon-->
<!--          size="16"-->
<!--          color="var(&#45;&#45;main-bg-color)"-->
<!--        >mdi-email-->
<!--        </v-icon>-->
        <a :href="'mailto:' + employee.contact_value">
          {{employee.contact_value}}
        </a>
      </div>
    </div>
</template>


<style scoped lang="scss">
.employee-card {
  background: #FFFFFF;
  border-bottom: 10px solid #72777A;
  border-top: 0.5px solid #72777A;
  border-left: 0.5px solid #72777A;
  border-right: 0.5px solid #72777A;
  border-radius: 4px;
  padding: 30px 30px 30px;
  text-align: center;
  transition-duration: 0.3s;
  color: black;
  
  &:hover {
    background-color: var(--bg-dark);
    color: white !important;
    border-bottom: 10px solid #a3acaf;
  }
  
  &:hover a {
    color: white !important;
  }
  
  &:hover &__deratment {
    color: var(--main-orange);
  }
  
  &__img {
    //bottom: -50px;
    //margin-bottom: -50px;
    margin-left: calc(50% - 100px);
    position: relative;
    margin-bottom: 15px;
    display: flex;
    justify-items: center;
    
    > img {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      object-fit: cover;
      object-position: 0 0;
    }
  }
  
  &__title {
    font-weight: 700;
    font-size: 20px;
    align-items: center;
    letter-spacing: 0.25px;
    text-align: center;
  }
  
  &__deratment {
    font-weight: 700;
    font-size: 16px;
    align-items: center;
    letter-spacing: 0.25px;
    text-align: center;
    color: var(--main-bg-color);
    padding-top: 6px;
    transition-duration: 0.3s;
  }
  
  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    margin: 5px 0 10px;
    align-items: center;
    letter-spacing: 0.25px;
    opacity: 0.6;
    padding-bottom: 6px;
    max-height: 56px;
  }
  
  &__contact {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin: 6px 0;
    align-items: center;
    letter-spacing: 0.25px;
    opacity: 0.8;
    max-height: 56px;
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
    }
  }
}

a {
  text-decoration: none;
  color: black !important;
  transition-duration: 0.3s;
}
</style>
