import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=65a373ab&scoped=true"
import script from "./ErrorPage.vue?vue&type=script&lang=js"
export * from "./ErrorPage.vue?vue&type=script&lang=js"
import style0 from "./ErrorPage.vue?vue&type=style&index=0&id=65a373ab&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65a373ab",
  null
  
)

export default component.exports