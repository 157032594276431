<script>
import {bus} from "../../../router/bus";

export default {
  name: "Article",
  data: () => ({
    article: [],
  }),
  
  mounted() {
    this.getNews(this.$route.params.id)
  },
  
  methods: {
    getNews(id) {
      this.$postapi(this.$address + this.$getters.getNewsBody.uri, {
        method: this.$getters.getNewsBody.name,
        id: id
      }).then(data => {
        if (data.error === 0) {
          this.article.push(JSON.parse(data.message))
//          console.log(this.article)
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
  }
}
</script>

<template>
  <div class="article">
    
    <div class="article__title">
      {{article[0][0].news_title === null ? article[0][0].short_text : article[0][0].news_title}}
    </div>
    
    <div class="article__text" v-html="article[0][0].short_text">
    </div>
    
    <br/>
    
    <div class="article__text" v-html="article[0][0].news_text">
    </div>
    
    <div class="article__date">
      {{article[0][0].news_date}}
    </div>
    
<!--  <div class="article__date">-->
<!--      {{article[0][0].user_name}}-->
<!--    </div>-->
  
  </div>
</template>

<style scoped lang="scss">
.article {
  width: 70%;
  margin: auto;
  padding: 2%;
  background: #FFFFFF;
  
  @media (max-width: 600px) {
    width: 96%;
  }

  &__title {
    text-align: left;
    font-size: 44px !important;
    margin: 15px 0;
    font-weight: 600;
    
    @media (max-width: 600px) {
      font-size: 22px !important;
    }
  }

  &__text {
    margin: auto;
    font-size: 14pt;
    color: #212121;
    
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  &__date {
    margin: 16px auto;
    font-size: 14pt;
    color: #92A1A6;
    
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
}

h1 {
  font-size: 50px !important;
}

.v-application .title {
  font-size: 20rem !important;
}

</style>
