<script>
export default {
  name: "DirectorMessage",
}
</script>

<template>
  <div class="article g-row">
    
    <div class="article__photo g-col-md-5 g-col-lg-5 g-col-sm-4 g-col-xs-2">
      <img src="../../assets/img/kovganko_full.jpg" class="g-col-md-5 g-col-lg-5 ">
    </div>
    
    <div class="article__text g-col-md-6 g-col-lg-6 g-col-sm-4 g-col-xs-2">
    
      <p>Я, Сергей Ковганко, основатель и руководитель проекта Vedexx.</p>
      
      <p> На момент написания этих строк мне 43 года, 25 из которых я занимаюсь логистикой.</p>
      
      <p> Сегодня мы оперируем собственным капиталом в 1 миллиард рублей, а объем импорта составляет более 20 тысяч теусов в год. Нам доверяют свои грузы такие компании как DNS, HAVAL, TDM Electric, Kuchenland, Северные стрелы, УралОпт и другие.</p>
      
      <p> Мы все время отвечаем себе на следующие вопросы:</p>
      
      <ul style="list-style: disc inside none">
        <li>какой опыт мы получили за это время? </li>
        <li>Какие выявили комплексные пробелы на рынке международных перевозок? </li>
        <li>Возможно ли это исправить?</li>
        <li>Возможно ли упростить жизнь участникам ВЭД?</li>
        <li>Если да, то в чем?</li>
        <li>Как мы собираемся это сделать?</li>
      </ul>
      
      <br>
      
      <p> Мы выявили тонкие места и пробелы в международной контейнерной логистике. Основная причина из которых - неполноценная информация и ответственность.</p>
      
      <p>Много информации проходит мимо заинтересованных лиц. По этой причине участники рынка совершают ошибки, которых могли бы не совершать, и несут расходы, которых могли бы не нести.</p>
      
      <p>Зная боли рынка, минусы и пробелы, мы создали Vedexx – инструмент, который поможет рынку контейнерных перевозок:</p>
      
      <ul style="list-style-type: none">
        <li>1. Не терять важное из вида.</li>
        <li>2. Не тратить напрасно время.</li>
        <li>3. Стать результативнее, эффективнее.</li>
        <li>4. Распространить знания лучших экспертов на весь рынок.</li>
      </ul>
      
      <br>
      
      <p> Наша задача - с помощью современных технологий сделать логистику простой и понятной.</p>
      
      <p>Изо дня в день мы будем совершенствовать платформу Vedexx, чтобы приносить как можно больше пользы.</p>
      
      <p>Поэтому мы открыты для ваших идей и предложений. Делитесь мыслями, критикой и наблюдениями.</p>
      
      <p>Давайте вместе менять мир логистики!</p>
      
      <p>Сергей Ковганко и команда Vedexx!</p>
    </div>
  
  </div>
</template>

<style scoped lang="scss">
.article {
  display: flex;
  width: 100%;
  margin: auto;
  //padding: 5%;
  background: #FFFFFF;
  
  &__photo {
    position: relative;
    
    > img {
      width: 100%;
      border-radius: 6px;
      border: 2px solid var(--main-bg-color);
    }
  }
  
  &__text {
    //width: 55%;
    text-align: left;
    font-size: 16px !important;
    font-weight: 500;
  }
}

</style>
