<script>
import {mediaArticles} from "../../jsons/media-articles";
import MediaArticleCard from "./MediaArticleCard";

export default {
  name: "MediaArticleListPage",
  
  components: {
    MediaArticleCard
  },
  
  data: () => ({
    articles: mediaArticles,
  }),
}
</script>

<template>
  <div class="media-page">
    
    <div class="media-page__title">
      СМИ о нас
    </div>
  
    <div class="media-page__list" v-if="articles.length > 0">
      <div
        v-for="news in articles"
        :key="news.id"
      >
        <MediaArticleCard :media="news"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.media-page {
  width: 100%;
  margin: auto;
  background: #FFFFFF;
  
  &__title {
    text-align: left;
    font-size: 44px !important;
    margin: 15px 0;
    font-weight: 700;
  }
  
  &__list {
    display: grid;
    width: 100%;
    margin-top: 20px;
    //padding: 10px 0;
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    
    @media (max-width: $mobile-width) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

h1 {
  font-size: 50px !important;
}

.v-application .title {
  font-size: 20rem !important;
}

</style>
