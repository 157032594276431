<template>
  <div class="info-block">
    <img class="info-block__image" src="../../assets/img/tech_work.svg">
    <div class="info-block__title">Vedexx временно недоступен! :(</div>
    <div class="info-block__text">На сайте ведутся технические работы,
      <br/>
      просим извинения за доставленные неудобства.
    </div>
  </div>
</template>

<script>

export default {
  name: "RegistrationDone",
  
  data: () => ({
    isSetting: []
  }),
  
  mounted() {
    this.checkIsSiteAvailable()
  },
  
  methods: {
    checkIsSiteAvailable() {
      this.$postapi(this.$address + this.$setters.getSettingByName.uri, {
        method: this.$setters.getSettingByName.name,
        setting_name: "is_under_construction"
      }).then(data => {
        if (data.error == 0) {
          this.isSetting = JSON.parse(data.message)
          if (this.isSetting.is_under_construction == 0){
            this.$router.push("/")
          }
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.info-block {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  margin: auto auto;
  height: 100%;
  text-align: center;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.87);
  overflow: hidden;
  
  &__image {
    display: flex;
    margin: 3% auto;
    max-width: 30%;
    min-height: 300px;
  
    @media (max-width: 620px) {
      max-width: 70%;
      margin: 7% auto;
    }
  }
  
  &__title {
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    margin: 10px 0;
  
    @media (max-width: 620px) {
      font-size: 30px;
      line-height: 34px;
    }
  }
  
  &__text {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
  
    @media (max-width: 620px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
}

</style>
