<script>
import NewsCard from "./NewsCard";
import {bus} from "../../../router/bus";

export default {
  name: 'NewsList',
  components: {
    NewsCard
  },
  
  data() {
    return {
      news: [],
    }
  },

  mounted() {
    this.getNews()
  },
  
  methods: {
    getNews() {
      this.$postapi(this.$address + this.$getters.getServiceNews.uri, {
        method: this.$getters.getServiceNews.name,
      }).then(data => {
        if (data.error === 0) {
          this.news.push(JSON.parse(data.message).reverse())
          this.sortNews()
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
  
    sortNews() {
      this.news[0].sort((a, b) => new Date(b.mdate) - new Date(a.mdate));
    }
  }
}
</script>

<template>
  <div class="news-page">
    
    <div class="news-page__title">Vedexx Новости</div>
    <hr class="news-page__line"/>
    
    <div v-if="news.length > 0">
      <div
        class="news-page__cards"
        v-for="report in news[0]"
        :key="report.id"
      >
        <NewsCard class="news-page__cards__card" :news="report"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.news-page {
  margin: 20px;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 600px) {
    margin: 10px;
  }

  &__title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 35px;
    color: #301E06;
  }
  
  &__line {
    margin: 8px 0 20px;
    color: black;
  }
  
  &__cards {
    width: 70%;
    margin: auto;
    padding-bottom: $size-12;
    background: #FFFFFF;
    box-shadow: 1.97853px 1.97853px 8.9034px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 8px 8px;
    
    @media (max-width: 600px) {
      margin-top: 5px;
      width: 100%;
      border-radius: 8px 8px;
    }
  }
}
</style>
