<script>
import {bus} from "../../router/bus";

export default {
  
  props: {
    fields: {
      type: [Array, Object],
    },
    
    rateInfo: {
      type: [Array, Object]
    }
  },
  
  name: "RatingForm",
  data: () => ({
    rating: 0,
    ratingFields: [],
    message: null
  }),
  
  watch: {
    rating() {
      if (this.rating === 5) {
        this.ratingFields = this.fields.filter(elem => elem.filter_val === 5)
      } else {
        this.ratingFields = this.fields.filter(elem => elem.filter_val != 5)
      }
      this.ratingFields.forEach(function (elem) {
        elem.mark_value = 0
      })
    }
  },
  
  mounted() {
    this.ratingFields = this.fields
  },
  
  methods: {
    setOrderMark() {
      bus.$emit("set_mark", { marks: this.ratingFields, mark_value: this.rating, description: this.message });
    }
  }
}

</script>

<template>
  <v-card
    class="elevation-16 mx-auto mb-16"
    width="700"
  >
    <v-card-title class="text-h5">
    Оцените поставку
    </v-card-title>
     <v-card-text >
       <span class="text-h6">Заказ №{{rateInfo.ord_num}}</span>
       <br/>
       Поставка {{rateInfo.place_from}} -> {{rateInfo.place_to}},
       {{rateInfo.gds_name}}
      
      <div class="text-center my-4" >
        <v-rating
          v-model="rating"
          color="yellow darken-3"
          background-color="grey darken-1"
          empty-icon="$ratingFull"
          hover
          large
        ></v-rating>
      </div>
      
      <div v-if="rating != 0">
        <v-chip
          color="var(--main-bg-color)"
          :outlined="field.mark_value == 0"
          dark
          class="my-1 rating-form__chip"
          v-for="field in ratingFields"
          :key="field.id"
          @click="field.mark_value === 0 ? field.mark_value = 1 : field.mark_value = 0"
        >
        {{field.name}}
        </v-chip>
      </div>
       <v-textarea
         v-if="rating != 0"
         class="mx-4 mt-4"
         label="Ваш комментарий"
         outlined
         rows="2"
         color="var(--main-bg-color)"
         v-model="message"
       ></v-textarea>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-space-between">
      <v-btn text to="/">
        Не сейчас
      </v-btn>
      <v-btn
        color="var(--main-bg-color)"
        text
        @click="setOrderMark"
      >
        Оценить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss">
.rating-form {
  
  &__chip {
    cursor: pointer;
  }
}
</style>
