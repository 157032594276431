<script>
import {bus} from "../../router/bus";

import EmployeeCard from "./EmployeeCard";
import MainInput from "./MainInput";

export default {
  name: "TeamPage",
  components: {
    EmployeeCard,
    MainInput
  },
  
  data: () => ({
    teamList: [],
    searchValue: '',
  }),
  
  computed: {
    teamFilterList() {
      return this.teamList.filter(item => item.first_name.indexOf(this.searchValue) !== -1 || item.second_name.indexOf(this.searchValue) !== -1)
    },
  },
  
  mounted() {
    this.getTeam()
  },
  
  methods: {
    getTeam() {
      this.$postapi(this.$address + this.$getters.getOurTeam.uri, {
        method: this.$getters.getOurTeam.name,
      }).then(data => {
        if (data.error === 0) {
          this.teamList = JSON.parse(data.message)
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
  }
}
</script>

<template>
  <div class="team">
    <div class="team__title">Наша команда представлена на выставке</div>
    
    <MainInput
      v-model.trim="searchValue"
      label="Поиск сотрудника..."
      class="team__input"
      color="var(--main-orange)"
      outlined
    />
    
    <div class="team__list">
      <EmployeeCard
        v-for="(employee, index) in teamFilterList"
        :key="index"
        :employee="employee"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.team {
  width: 100%;
  max-width: 1450px;
  margin: auto;
  padding: 0 5% 5% 5%;
  background: #FFFFFF;
  
  &__title {
    text-align: left;
    font-size: 44px !important;
    margin: 15px 0;
    font-weight: 700;
  }
  
  &__input {
    width: 25%;
    margin-top: 30px;
  }
  
  &__list {
    display: grid;
    width: 100%;
    margin-top: 20px;
    padding: 10px 0;
    grid-column-gap: 20px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
  }
}


</style>
