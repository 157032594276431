<template>
  <div class="main-input">
    <v-text-field
      :color="color"
      :outlined="outlined"
      :label="label"
      :error="error ? true : false"
      :value="value"
      :disabled="disabled"
      :type="sh_pass ? 'text' : type"
      :append-icon="(type !== 'password') ? '' : (sh_pass ? 'mdi-eye' : 'mdi-eye-off')"
      :placeholder="placeholder"
      :rules="rules"
      :hint="hint"
      @keyup.enter="$emit('keyup', $event.target.value)"
      @input="$emit('input', $event)"
      @click:append="sh_pass = !sh_pass"
    />
    
    <div v-if="error" class="error_text">
      {{error}}
    </div>
    
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MainInput",
  props: {
    placeholder: {
      type: String,
    },

    type: {
      type: String
    },

    value: {
      type: [String, Object, ],
      required: true
    },

    label: {
      type: String,
      required: false
    },

    outlined: {
      type: Boolean,
      default: false
    },

    rules: {
      type: [String, Array, Object],
      required: false
    },

    error: {
      type: String,
      required: false
    },
  
    hint: {
      type: String,
      required: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    color: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      sh_pass: false,
    }
  }
}
</script>

<style scoped>
.main-input {
  width: 100%;
}
.error_text {
  color: rgb(255 82 82);
  margin: -15px 0 10px 0;
  font-size: 12px;
}
</style>
